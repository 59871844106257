<template>
  <div>
    <header>
      <div   v-show="this.$route.path !== '/home/vipForm/vipDetailPage'" class="shop-name">
        &nbsp;&nbsp;会员报表
      </div>
    </header>
    <main>
      <div  v-show="this.$route.path !== '/home/vipForm/vipDetailPage'" class="vip-type-list">
        <!-- <div @click="this.$router.push({name:'all'});vipListOne = true;vipListTwo = false;vipListThree = false" :style="vipListOne?'border-bottom:3px solid #287AB4':''" >全部会员</div>
        <div @click="this.$router.push({name:'integral'});vipListOne = false;vipListTwo = true;vipListThree = false" :style="vipListTwo?'border-bottom:3px solid #287AB4':''">会员余额汇总</div>
        <div @click="this.$router.push({name:'remainingSum'});vipListOne = false;vipListTwo = false;vipListThree = true" :style="vipListThree?'border-bottom:3px solid #287AB4':''">会员积分汇总</div> -->
        <el-tabs v-model="routerName" @tab-click="handleClick">
          <el-tab-pane label="全部会员" name="all"></el-tab-pane>
          <el-tab-pane label="会员余额汇总" name="integral"></el-tab-pane>
          <el-tab-pane label="会员积分汇总" name="remainingSum"></el-tab-pane>
        </el-tabs>
      </div>
      <div style="padding:10px">
      <!-- <router-view v-if='comState' v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>         -->
      <div v-if="routerStatus">
        <router-view></router-view> 
      </div>
           
      </div>

    </main>
  </div>
</template>

<script>
import {ref} from'vue'
import {useRouter} from'vue-router'
import {postData} from'@a'
export default {

setup(){
  let router = useRouter()
  let vipListThree = ref(false)
  let vipListTwo = ref(false)
  let vipListOne = ref(true)
  let routerName = ref('all')
  let routerStatus = ref(false)
  const handleClick = (tab)=>{
    if(tab.props.label === '全部会员'){
      router.push({name:'all'})
    }else if(tab.props.label === '会员余额汇总'){
      router.push({name:'integral'})
    }else if(tab.props.label === '会员积分汇总'){
      router.push({name:'remainingSum'})
    }
  }
  let comState = ref(0)
  let cardList = ref('1')
  const infoCardList = async () => {
    const param = {
      shopIds:window.localStorage.getItem('shopIds')
    }
    try {
      let res = await(postData('report/memberCardType.json',param))
      for(let i =0;i<res.data.length;i++){
        res.data[i].value = res.data[i].cardId
        res.data[i].label = res.data[i].cardName
      }
      res.data.unshift({value:'全部会员',laber:'全部会员'})
      cardList.value = JSON.stringify(res.data)
      window.sessionStorage.setItem('cardList',cardList.value)
      routerStatus.value = true
      comState.value = 1
    } catch (err) {
      console.log(err)
    }
  }
  infoCardList()
  return{
    comState,
    vipListOne,
    vipListTwo,
    vipListThree,
    routerName,
    handleClick,
    cardList,
    routerStatus
  }
}
}
</script>

<style scoped>
/* main{ */
  /* margin-top: 20px; */

/* } */
.shop-name{
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  text-align: left;
  line-height: 60px;
  height: 60px;
}
.vip-type-list{
  display: flex;
  justify-content: center;
  background-color: #D9EFFF;
  height: 65px;
}
.vip-type-list>div{
  height: 100%;
  line-height: 65px;
  padding:0 55px;
  font-size: 18px;
  color: #333;
}
</style>
<style>
.el-tabs__item{
  font-size: 16px;
}
/* .el-tabs__item.is-active{
  color: #55BFF0;
  font-size: 18px;
}
.el-tabs__item:hover {
    color: #55BFF0;
    cursor: pointer;
}
.el-tabs__active-bar{
background-color:#287AB4;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #409eff;
} */
</style>